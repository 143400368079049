import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import BackButton from "../../../components/buttons/new/BackButton"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import SecondaryButton from "../../../components/buttons/SecondaryButton"
import LoadingSpinner from "../../../components/Loader"
import { HealthPlan, MealPlan } from "../../../utils/api-objects/meal-plan"
import { Nutritionist, Patient } from "../../../utils/api-objects/Patient"
import { SurveyCompleted } from "../../../utils/api-objects/SurveyCompleted"
import { TempStat } from "../../../utils/api-objects/TempStat"
import { getAppointmentsByUserEmail } from "../../../utils/apis/booking-service"
import { getAllExamsByType, getAllMealPlanPdfsByType, getAnswersByIdsAndUserId, getLastSurveyUpdateByUserId, getSurveysByUserId } from "../../../utils/apis/emr-service"
import { getHealthPlansByUserId, getMealPlanApi } from "../../../utils/apis/plan-service"
import { getBloodTrackerByUserId, getTrackerByUserId } from "../../../utils/apis/tracker-service"
import { getStatsByEmail } from "../../../utils/apis/user-service"
import { useAppointments } from "../../../utils/context/AppointmentsContext"
import { useAuth } from "../../../utils/context/AuthContext"
import { usePatients } from "../../../utils/context/PatientsContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { AppointmentStatus, BloodTestTrackingStatus, BucketFolder, ExpertRole, Folder, PdfType, SurveyType, TrackingStatus } from "../../../utils/Enums"
import { getFormattedDateWithInput, handleAcquaAnswer, handleBloodTrackerText, handleDayOfMonth, handleDaysDifference, handleDigestioneAnswer, handleDnaTrackerText, handleEnergiaAnswer, handleEsercizioAnswer, handleHoursDifference, handleMinutesDifference, handleMonth, handleObiettivoAnswer, handlePurchasePriority, handleQuestionIds, handleSonnoAnswer, handleYear } from "../../../utils/Functions"
import { Appointment } from "../components/Appointment"
import { OldAppointmentWidget, SmallAppointmentWidget } from "../components/NewAppointmentWidget"
import { PdfExam } from "../components/PdfExam"


const SingleCustomer = () => {
    const { auth } = useAuth()
    const role = auth.role
    const idToken = auth.idToken

    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const patientId = searchParams.get("patient_id");
    const { patientsState } = usePatients()
    const patients = patientsState.patients
    const { userData } = useUserData()
    const email = userData.email
    const { appointmentsState } = useAppointments()
    const allFutureActiveAppointments = appointmentsState.allFutureActiveAppointments
    const allOldActiveAppointments = appointmentsState.allOldActiveAppointments
    const navigate = useNavigate()

    const [patient, setPatient] = useState<Patient | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isHealthAssessmentLoading, setIsHealthAssessmentLoading] = useState(true)
    const [isDnaLoading, setIsDnaLoading] = useState(true);
    const [isBloodLoading, setIsBloodLoading] = useState(true);
    const [isCrossResultLoading, setIsCrossResultLoading] = useState(true)
    const [isHealthPlanLoading, setIsHealthPlanLoading] = useState(true)
    const [isMealPlanLoading, setIsMealPlanLoading] = useState(true)

    const [lastSurveyUpdate, setLastSurveyUpdate] = useState("")
    const [dnaTracker, setDnaTracker] = useState<{ status: string | null, date: string | null }>({ status: null, date: null })
    const [dnaTestPickupDate, setDnaTestPickupDate] = useState<string>()
    const [bloodTracker, setBloodTracker] = useState<{ status: string | null, date: string | null }>({ status: null, date: null })
    const [bloodTestPickupDate, setBloodTestPickupDate] = useState<string>()
    const [crossResultsDropDate, setCrossResultsDropDate] = useState("")
    const [dietId, setDietId] = useState("")
    const [dietPdfAvailable, setDietPdfAvailable] = useState(false)
    const [shoppingListId, setShoppingListId] = useState("")
    const [shoppingListPdfAvailable, setShoppingListPdfAvailable] = useState(false)
    const [eatingHabitsId, setEatingHabitsId] = useState("")
    const [eatingHabitsPdfAvailable, setEatingHabitsPdfAvailable] = useState(false)
    const [tipsAndRecepesId, settipsAndRecepesId] = useState("")
    const [tipsAndRecepesPdfAvailable, setTipsAndRecepesPdfAvailable] = useState(false)
    const [seasonalFruitsAndVegetablesId, setSeasonalFruitsAndVegetablesId] = useState("")
    const [seasonalFruitsAndVegetablesPdfAvailable, setSeasonalFruitsAndVegetablesPdfAvailable] = useState(false)
    const mealPlanPdfsAvailable = dietPdfAvailable === true || shoppingListPdfAvailable === true || eatingHabitsPdfAvailable === true || tipsAndRecepesPdfAvailable === true || seasonalFruitsAndVegetablesPdfAvailable === true
    const [topHeroFood, setTopHeroFood] = useState<string[]>([])
    const [topNutritionRecommendationsFood, setTopNutritionRecommendationsFood] = useState<string[]>([])
    const [topIntegrators, setTopIntegrators] = useState<string[]>([])
    const [keyParameters, setKeyParameters] = useState<string[]>([])
    const [mealPlan, setMealPlan] = useState<MealPlan>()
    const mealPlanAvailable = topHeroFood.length > 0 || topIntegrators.length > 0 || topNutritionRecommendationsFood.length > 0 || keyParameters.length > 0

    const ids = handleQuestionIds()

    const [obiettivo, setObiettivo] = useState("");
    const [acqua, setAcqua] = useState("");
    const [peso, setPeso] = useState("");
    const [sonno, setSonno] = useState("");
    const [energia, setEnergia] = useState("");
    const [digestione, setDigestione] = useState("");
    const [esercizio, setEsercizio] = useState("");

    const [stats, setStats] = useState<TempStat[]>([])

    const [surveysCompleted, setSurveysCompleted] = useState<SurveyCompleted[]>()

    const [userAppointments, setUserAppointments] = useState<Appointment[]>()

    const [mostRecentHealthPlan, setMostRecentHealthPlan] = useState<HealthPlan>()

    useEffect(() => {
        if (!patientId) return
        if (!patients) {
            setIsLoading(false);
            return;
        }
        const foundPatient = patients.find((p) => p.user_id === patientId);
        if (!foundPatient) return
        setPatient(foundPatient);

        if (!email || !idToken) {
            setIsLoading(false)
            return
        }

        getTrackerByUserId(
            patientId,
            idToken,
            (trackers) => {
                if (trackers.length > 0) {
                    const tracker = trackers[0]
                    const statusText = handleDnaTrackerText(tracker.tracking_status)
                    const date = tracker.updated_at
                    const dateText = `${handleDayOfMonth(date)} ${handleMonth(date)} ${handleYear(date)}`
                    setDnaTracker({ status: statusText, date: dateText })
                    if (
                        tracker.tracking_status === TrackingStatus.PickupRequested ||
                        tracker.tracking_status === TrackingStatus.Departed ||
                        tracker.tracking_status === TrackingStatus.DeliveredToLaboratory ||
                        tracker.tracking_status === TrackingStatus.StartingToExtract ||
                        tracker.tracking_status === TrackingStatus.StartingAnalysis ||
                        tracker.tracking_status === TrackingStatus.AnalysisDone ||
                        tracker.tracking_status === TrackingStatus.ReportGenerated
                    ) {
                        // Find the history entry for "pickup-requested"
                        const pickupEntry = tracker.tracking_history.find(
                            (entry) => entry.status === TrackingStatus.PickupRequested
                        );

                        if (pickupEntry) {
                            const date = pickupEntry.date;
                            setDnaTestPickupDate(`${handleDayOfMonth(date)} ${handleMonth(date)} ${handleYear(date)}`)
                        }
                    }
                }
                setIsDnaLoading(false)
            },
            (error) => {
                console.log(error)
                setIsDnaLoading(false)
            }
        )

        getBloodTrackerByUserId(
            patientId,
            idToken,
            (trackers) => {
                const tracker = trackers[0]
                const status = tracker.tracking_status
                const statusText = handleBloodTrackerText(status)
                const date = tracker.updated_at
                const dateText = `${handleDayOfMonth(date)} ${handleMonth(date)} ${handleYear(date)}`
                setBloodTracker({ status: statusText, date: dateText })
                if (
                    status === BloodTestTrackingStatus.PickupRequested ||
                    status === BloodTestTrackingStatus.DeliveredToLab ||
                    status === BloodTestTrackingStatus.ReportGenerated
                ) {
                    // Find the history entry for "pickup-requested"
                    const pickupEntry = tracker.tracking_history.find(
                        (entry) => entry.status === BloodTestTrackingStatus.PickupRequested
                    );

                    if (pickupEntry) {
                        const date = pickupEntry.date;
                        setBloodTestPickupDate(`${handleDayOfMonth(date)} ${handleMonth(date)} ${handleYear(date)}`)
                    }
                }
                setIsBloodLoading(false)
            },
            (error) => {
                console.log(error)
                setIsBloodLoading(false)
            }
        )

        getLastSurveyUpdateByUserId(
            patientId,
            idToken,
            (date) => {
                const dateText = `${handleDayOfMonth(date)} ${handleMonth(date)} ${handleYear(date)}`
                setLastSurveyUpdate(dateText)
                setIsHealthAssessmentLoading(false)
            },
            (error) => {
                console.log(error)
                setIsHealthAssessmentLoading(false)
            },
        )

        getSurveysByUserId(
            idToken,
            patientId,
            (surveys) => {
                setSurveysCompleted(surveys)
            },
            (error) => {
                console.log(error)
            },
        )

        getAllExamsByType(
            patientId,
            idToken,
            BucketFolder.CrossResults,
            (exams) => {
                if (exams.length > 0) {
                    const examDropDate = exams[0].uploaded_at
                    const dateText = `${handleDayOfMonth(examDropDate)} ${handleMonth(examDropDate)} ${handleYear(examDropDate)}`
                    setCrossResultsDropDate(dateText)
                }
                setIsCrossResultLoading(false)
            },
            (error) => {
                console.log(error)
                setIsCrossResultLoading(false)
            },
        )

        getHealthPlan()

        const mealPlanPdfPromises = [
            getAllMealPlanPdfsByTypeNew(patientId, idToken, BucketFolder.Diet),
            getAllMealPlanPdfsByTypeNew(patientId, idToken, BucketFolder.ShoppingList),
            getAllMealPlanPdfsByTypeNew(patientId, idToken, BucketFolder.Eatinghabits),
            getAllMealPlanPdfsByTypeNew(patientId, idToken, BucketFolder.TipsAndRecepes),
            getAllMealPlanPdfsByTypeNew(patientId, idToken, BucketFolder.FruitsAndVegetables),
        ];

        const mealPlanApiKeysPromise = new Promise<void>((resolve, reject) => {
            getAnswersByIdsAndUserId(
                ids!,
                patientId!,
                idToken!,
                (answers) => {
                    setObiettivo(handleObiettivoAnswer(answers.filter(ans => ans.question_id.toString() === ids![0])[0].value[0]))
                    setAcqua(handleAcquaAnswer(answers.filter(ans => ans.question_id.toString() === ids![1])[0].value[0]))
                    setPeso(answers.filter(ans => ans.question_id.toString() === ids![2])[0].value[0])
                    setSonno(handleSonnoAnswer(answers.filter(ans => ans.question_id.toString() === ids![3])[0].value[0]))
                    setEnergia(handleEnergiaAnswer(answers.filter(ans => ans.question_id.toString() === ids![4])[0].value[0]))
                    setDigestione(handleDigestioneAnswer(answers.filter(ans => ans.question_id.toString() === ids![5])[0].value[0]))
                    setEsercizio(handleEsercizioAnswer(answers.filter(ans => ans.question_id.toString() === ids![6])[0].value[0]))
                },
                (error) => {
                    console.log(error)
                },
            )
        })

        const mealPlanApiPromise = new Promise<void>((resolve, reject) => {
            getMealPlanApi(
                idToken,
                patientId,
                (mealPlan) => {
                    setMealPlan(mealPlan)
                    setKeyParameters(mealPlan.data.key_parameters)
                    setTopHeroFood(mealPlan.data.top_hero_foods)
                    setTopIntegrators(mealPlan.data.top_integratori)
                    setTopNutritionRecommendationsFood(mealPlan.data.nutrition_recommendations)
                    resolve();
                },
                (error) => {
                    console.log(error);
                    reject(error); // Reject the promise on failure
                }
            );
        });

        // Use Promise.all to wait for all meal plan PDF API calls to finish
        // Combine all promises into a single Promise.all
        Promise.all([...mealPlanPdfPromises, mealPlanApiPromise, mealPlanApiKeysPromise])
            .then(() => {
                // After all API calls are complete, set the boolean to false
                setIsMealPlanLoading(false);
            })
            .catch((error) => {
                console.log('Error in one of the API calls:', error);
                setIsMealPlanLoading(false); // Optionally handle failure here
            })

        setIsLoading(false);
    }, [patients, patientId]);

    const getHealthPlan = async () => {
        try {
            const hp = await getHealthPlansByUserId(
                patientId!,
                idToken!,
            )
            setMostRecentHealthPlan(hp[0])
        } catch {
            console.log("Health plan non trovato")
        }
    }

    // Helper function for making API calls (if needed)
    function getAllMealPlanPdfsByTypeNew(patientId: string, idToken: string, bucket: BucketFolder) {
        return new Promise((resolve, reject) => {
            // Your existing getAllMealPlanPdfsByType logic, modify to return a promise
            getAllMealPlanPdfsByType(
                patientId,
                idToken,
                bucket,
                (exams) => {
                    if (exams.length > 0) {
                        switch (bucket) {
                            case BucketFolder.Diet:
                                setDietPdfAvailable(true)
                                setDietId(exams[0].filename.split("_")[1].split(".")[0])
                                break
                            case BucketFolder.ShoppingList:
                                setShoppingListPdfAvailable(true)
                                setShoppingListId(exams[0].filename.split("_")[1].split(".")[0])
                                break
                            case BucketFolder.Eatinghabits:
                                setEatingHabitsPdfAvailable(true)
                                setEatingHabitsId(exams[0].filename.split("_")[1].split(".")[0])
                                break
                            case BucketFolder.TipsAndRecepes:
                                setTipsAndRecepesPdfAvailable(true)
                                settipsAndRecepesId(exams[0].filename.split("_")[1].split(".")[0])
                                break
                            case BucketFolder.FruitsAndVegetables:
                                setSeasonalFruitsAndVegetablesPdfAvailable(true)
                                setSeasonalFruitsAndVegetablesId(exams[0].filename.split("_")[1].split(".")[0])
                                break
                            default:
                                break
                        }
                    }
                    resolve(exams); // Resolve the promise on success
                },
                (error) => {
                    console.log(error);
                    reject(error); // Reject the promise on error
                }
            );
        });
    }

    useEffect(() => {
        if (patient) {
            retrieveStats();
            getApps()
        }
    }, [patient]);

    if (isLoading) {
        return (
            <div className="w-full h-full pt-[20px] flex flex-col items-center ">
                <div>
                    Attendi un attimo... <br />
                    Stiamo caricando i dati del paziente
                </div>
            </div>
        );
    }

    if (!patient) {
        return (
            <div className="w-full h-full flex flex-col items-center">
                <div>
                    Paziente non trovato
                </div>
                <button
                    onClick={() => navigate("/platform-experts/customers")}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Torna a clienti
                </button>
            </div>
        );
    }

    const retrieveStats = async () => {
        const stats = await getStatsByEmail(patient.email, idToken!)
        setStats(stats)
    }

    const getApps = () => {
        getAppointmentsByUserEmail(
            idToken!,
            patient?.email!,
            (appointments) => {
                console.log("Appointments fetched correctly");
                const allAppointments: Appointment[] = appointments.map((appointment) => ({
                    date: appointment.payload.scheduled_event.start_time,
                    patientName: appointment.payload.name,
                    patientEmail: appointment.email,
                    editUrl: appointment.payload.reschedule_url,
                    connectUrl: appointment.payload.scheduled_event.location.join_url,
                    cancelUrl: appointment.payload.cancel_url,
                    remainingDays: handleDaysDifference(appointment.payload.scheduled_event.start_time),
                    remainingHours: handleHoursDifference(appointment.payload.scheduled_event.start_time),
                    remainingMinutes: handleMinutesDifference(appointment.payload.scheduled_event.start_time),
                    status: appointment.payload.status,
                    expert_email: appointment.organizer_email,
                }));
                setUserAppointments(allAppointments)
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const handleLoginSuccessNumber = () => {
        const row = stats
            .filter(stat => stat.event_type.startsWith("login_success"))[0]
        if (row) {
            return row.counter
        } else {
            return 0
        }
    }

    const handleLoginFailedNumber = () => {
        const rows = stats
            .filter(stat => stat.event_type.startsWith("login_failed"))
        if (rows.length > 0) {
            const counter = rows.reduce((sum, stat) => sum + (stat.counter), 0)
            return counter
        }
        return 0
    }

    const handleCheckUpSurveyNumber = () => {
        if (!surveysCompleted) return 0
        const rows = surveysCompleted
            .filter(s => s.survey_type === SurveyType.CheckupSurvey)
        if (rows.length > 0) {
            return rows.length
        }
        return 0
    }

    const futureApp = allFutureActiveAppointments?.filter(app => app.patientEmail === patient.email)
    const oldApp = allOldActiveAppointments?.filter(app => app.patientEmail === patient.email).reverse()
    const oldNutriApp = oldApp?.filter(app => app.expert_email !== "elvira.pistolesi@holifya.com")
    const oldExpertApp = oldApp?.filter(app => app.expert_email === "elvira.pistolesi@holifya.com")

    return (
        <div className="w-full h-full flex flex-col pt-[20px] pl-[70px]">
            <BackButton
                text="Torna a clienti"
                onClick={() => navigate("/platform-experts/customers")}
            />
            <div className="w-[89%] flex items-center justify-between mt-[20px]">
                <div className="flex flex-col">
                    <div className="flex items-end">
                        <div className="font-bold text-[35px] leading-[35px]">{patient.user_name} {patient.user_family_name}</div>
                        <div className="font-bold text-[22px] leading-[25px] text-holifya-blue ml-[20px]">{handlePurchasePriority(patient)}</div>
                    </div>
                    <div className="mt-[10px] font-normal text-[16px] leading-[33px]">{patient.email} {role !== ExpertRole.Nutritionist && `|| ${patient.user_mobile_phone}`}</div>
                </div>
                {
                    role !== ExpertRole.Admin && <div className="flex space-x-[20px]">
                        {
                            role !== ExpertRole.Nutritionist &&
                            <div className="w-[300px] h-[50px]">
                                <SecondaryButton
                                    text={`Crea ${!mostRecentHealthPlan ? "" : "nuovo"} Health Plan`}
                                    pointer={true}
                                    disabled={false}
                                    uppercase={false}
                                    onClick={() => navigate(`health-plan-page?patient_id=${patient.user_id}`)} />
                            </div>
                        }
                        <div className="w-[300px] h-[50px]">
                            <PrimaryButton
                                text={`Crea ${(mealPlanAvailable || mealPlanPdfsAvailable) ? "nuovo" : ""} Meal plan`}
                                pointer={true}
                                disabled={false}
                                uppercase={false}
                                onClick={() => navigate(`meal-plan-page?patient_id=${patient.user_id}`)}
                            />
                        </div>
                    </div>}
            </div>
            <div className="w-full flex-1 pb-[20px]">
                <div className="w-[89%] h-full flex justify-between">
                    <div className="w-[70%] h-full pr-[5%] pt-[20px] flex flex-col space-y-[35px]">
                        <TestAndAssessmentBox patient={patient} lastSurveyUpdate={lastSurveyUpdate} dnaTracker={dnaTracker} bloodTracker={bloodTracker} crossResultsDropDate={crossResultsDropDate} isHealthAssessmentLoading={isHealthAssessmentLoading} isDnaLoading={isDnaLoading} isBloodLoading={isBloodLoading} isCrossResultLoading={isCrossResultLoading} />
                        {
                            mostRecentHealthPlan && <MostRecentHealthPlanBox hp={mostRecentHealthPlan} onClick={() => navigate(`view-health-plan-page?patient_id=${patient.user_id}`)} />
                        }
                        {
                            (mealPlanPdfsAvailable || mealPlanAvailable) && mealPlan ?
                                <MealPlanBox
                                    dietId={dietId}
                                    dietPdfAvailable={dietPdfAvailable}
                                    shoppingListId={shoppingListId}
                                    shoppingListPdfAvailable={shoppingListPdfAvailable}
                                    eatingHabitsId={eatingHabitsId}
                                    eatingHabitsPdfAvailable={eatingHabitsPdfAvailable}
                                    tipsAndRecepesId={tipsAndRecepesId}
                                    tipsAndRecepesPdfAvailable={tipsAndRecepesPdfAvailable}
                                    fruitsAndVegetablesId={seasonalFruitsAndVegetablesId}
                                    fruitsAndVegetablesPdfAvailable={seasonalFruitsAndVegetablesPdfAvailable}
                                    topHeroFood={topHeroFood}
                                    topIntegrators={topIntegrators}
                                    topNutritionRecommendations={topNutritionRecommendationsFood}
                                    keyParameters={keyParameters}
                                    obiettivo={obiettivo}
                                    acqua={acqua}
                                    peso={peso}
                                    sonno={sonno}
                                    energia={energia}
                                    digestione={digestione}
                                    esercizio={esercizio}
                                    mealPlan={mealPlan}
                                    patient={patient}
                                /> : isMealPlanLoading && <LoadingSpinner />}
                        <SharedFilesBox patient={patient} />
                        {
                            role && role !== ExpertRole.Nutritionist && <NutritionistBox nutritionist={patient.nutritionist} />
                        }
                    </div>
                    <div className="w-[30%] h-full pt-[20px] space-y-[25px]">
                        {
                            role === ExpertRole.Admin &&
                            <div className="w-full space-y-[20px]">
                                <div className="font-bold text-[16px] leading-[33px]">
                                    Stats
                                </div>
                                <div className="text-[12px] leading-[17px] space-y-[20px]">
                                    <div>
                                        <div className="font-bold">Data registrazione</div>
                                        <div className="">{getFormattedDateWithInput(patient.created_at)}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Numero login success</div>
                                        <div className="">{handleLoginSuccessNumber()}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Numero login failed</div>
                                        <div className="">{handleLoginFailedNumber()}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Data Health assessment</div>
                                        <div className="">{surveysCompleted ? getFormattedDateWithInput(surveysCompleted.filter(s => s.survey_type === SurveyType.HealthAssessment)[0].created_at) : "Mai"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Numero check up survey fatti</div>
                                        <div className="">{handleCheckUpSurveyNumber()}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Primo consulto con MD</div>
                                        <div className="">
                                            {
                                                userAppointments !== undefined ?
                                                    userAppointments.filter(app => app.expert_email === "elvira.pistolesi@holifya.com").filter(app => app.remainingMinutes <= -30).filter(app => app.status === AppointmentStatus.Active).length > 0 ? getFormattedDateWithInput(userAppointments.filter(app => app.expert_email === "elvira.pistolesi@holifya.com").filter(app => app.remainingMinutes <= -30).filter(app => app.status === AppointmentStatus.Active)[0].date) : "Mai" :
                                                    "Mai"
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Ultimo consulto con MD</div>
                                        <div className="">
                                            {
                                                userAppointments !== undefined ?
                                                    userAppointments.filter(app => app.expert_email === "elvira.pistolesi@holifya.com").filter(app => app.remainingMinutes <= -30).filter(app => app.status === AppointmentStatus.Active).length > 0 ? getFormattedDateWithInput(userAppointments.filter(app => app.expert_email === "elvira.pistolesi@holifya.com").filter(app => app.remainingMinutes <= -30).filter(app => app.status === AppointmentStatus.Active).reverse()[0].date) : "Mai" :
                                                    "Mai"
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Consulti con MD fatti</div>
                                        <div className="">
                                            {
                                                userAppointments !== undefined ?
                                                    userAppointments.filter(app => app.expert_email === "elvira.pistolesi@holifya.com").filter(app => app.remainingMinutes <= -30).filter(app => app.status === AppointmentStatus.Active).length :
                                                    "Mai"
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Primo consulto con nutri</div>
                                        <div className="">
                                            {
                                                userAppointments !== undefined ?
                                                    userAppointments.filter(app => app.expert_email !== "elvira.pistolesi@holifya.com").filter(app => app.remainingMinutes <= -30).filter(app => app.status === AppointmentStatus.Active).length > 0 ? getFormattedDateWithInput(userAppointments.filter(app => app.expert_email !== "elvira.pistolesi@holifya.com").filter(app => app.remainingMinutes <= -30).filter(app => app.status === AppointmentStatus.Active)[0].date) : "Mai" :
                                                    "Mai"
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Ultimo consulto con nutri</div>
                                        <div className="">
                                            {
                                                userAppointments !== undefined ?
                                                    userAppointments.filter(app => app.expert_email !== "elvira.pistolesi@holifya.com").filter(app => app.remainingMinutes <= -30).filter(app => app.status === AppointmentStatus.Active).length > 0 ? getFormattedDateWithInput(userAppointments.filter(app => app.expert_email !== "elvira.pistolesi@holifya.com").filter(app => app.remainingMinutes <= -30).filter(app => app.status === AppointmentStatus.Active).reverse()[0].date) : "Mai" :
                                                    "Mai"
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Consulti con nutri fatti</div>
                                        <div className="">{
                                            userAppointments !== undefined ?
                                                userAppointments.filter(app => app.expert_email !== "elvira.pistolesi@holifya.com").filter(app => app.remainingMinutes <= -30).filter(app => app.status === AppointmentStatus.Active).length :
                                                "Mai"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Data richiesta ritiro dna</div>
                                        <div className="">{dnaTestPickupDate || "Mai"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Data richiesta ritiro blood</div>
                                        <div className="">{bloodTestPickupDate || "Mai"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Primo MP deployed</div>
                                        <div className="">{"TDB"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Ultimo MP deployed</div>
                                        <div className="">{"TDB"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Numero MP deployed</div>
                                        <div className="">{"TDB"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Primo HP deployed</div>
                                        <div className="">{"TDB"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Ultimo HP deployed</div>
                                        <div className="">{"TDB"}</div>
                                    </div>
                                    <div>
                                        <div className="font-bold">Numero HP deployed</div>
                                        <div className="">{"TDB"}</div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            futureApp && oldApp && <AppointmentsBox
                                futureAppointments={futureApp}
                                oldAppointments={oldApp}
                                nutritionist={`${patient.nutritionist.name} ${patient.nutritionist.family_name}`}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleCustomer

const MostRecentHealthPlanBox: React.FC<{
    hp: HealthPlan,
    onClick: () => void,
}> = ({
    hp,
    onClick,
}) => {
        return (
            <div className="space-y-[25px]">
                <div className="font-bold text-[16px] leading-[33px]">Health Plan</div>
                <FolderBox
                    folder={Folder.HealthPlan}
                    pointer={true}
                    textThree={`Ultimo aggiornamento: ${handleDayOfMonth(hp.updated_at)} ${handleMonth(hp.updated_at)} ${handleYear(hp.updated_at)}`}
                    onClick={onClick}
                />
            </div>
        )
    }

interface TestProps {
    patient: Patient,
    lastSurveyUpdate: string,
    dnaTracker: { status: string | null, date: string | null },
    bloodTracker: { status: string | null, date: string | null },
    crossResultsDropDate: string,
    isHealthAssessmentLoading: boolean,
    isDnaLoading: boolean,
    isBloodLoading: boolean,
    isCrossResultLoading: boolean,
}

const TestAndAssessmentBox: React.FC<TestProps> = ({
    patient,
    lastSurveyUpdate,
    dnaTracker,
    bloodTracker,
    crossResultsDropDate,
    isHealthAssessmentLoading,
    isDnaLoading,
    isBloodLoading,
    isCrossResultLoading,
}) => {
    const navigate = useNavigate()
    return (
        <div className="space-y-[25px]">
            <div className="font-bold text-[16px] leading-[33px]">Test e assessment</div>
            <div className="space-y-[20px]">
                <FolderBox
                    folder={Folder.HealthAssessment}
                    pointer={lastSurveyUpdate !== ""}
                    textTwo=""
                    textThree={
                        lastSurveyUpdate !== "" ? `Ultimo aggiornamento: ${lastSurveyUpdate}` : "Non disponibile"
                    }
                    onClick={() => { navigate(`answers?patient_id=${patient.user_id}`) }}
                    isLoading={isHealthAssessmentLoading}
                />
                <FolderBox
                    folder={Folder.DnaTest}
                    pointer={dnaTracker.status === "Risultato ricevuto"}
                    textTwo={patient.swabcode !== null ? patient.swabcode : ""}
                    textThree={
                        dnaTracker.status !== null
                            ? dnaTracker.status === "Risultato ricevuto"
                                ? `${dnaTracker.status}: ${dnaTracker.date}`
                                : dnaTracker.status
                            : "Non disponibile"
                    }
                    onClick={() => { navigate(`dna-test-page?patient_id=${patient.user_id}`) }}
                    isLoading={isDnaLoading}
                />
                <FolderBox
                    folder={Folder.BloodTest}
                    pointer={patient.bloodcodes.length > 0 && bloodTracker.status === "Risultato ricevuto"}
                    textTwo={patient.bloodcodes !== null ? patient.bloodcodes[patient.bloodcodes.length - 1] : ""}
                    textThree={
                        bloodTracker.status !== null
                            ? bloodTracker.status === "Risultato ricevuto"
                                ? `${bloodTracker.status}: ${bloodTracker.date}`
                                : bloodTracker.status
                            : "Non disponibile"
                    }
                    onClick={() => { navigate(`blood-test-page?patient_id=${patient.user_id}`) }}
                    isLoading={isBloodLoading}
                />
                <FolderBox
                    folder={Folder.ResultsAndProgresses}
                    pointer={crossResultsDropDate !== ""}
                    textThree={crossResultsDropDate !== "" ? `Risultato ricevuto: ${crossResultsDropDate}` : "Non disponibile"}
                    onClick={() => { navigate(`cross-results-page?patient_id=${patient.user_id}`) }}
                    isLoading={isCrossResultLoading}
                />
            </div>
        </div>
    )
}

interface HealthPlanProps {
    healthPlan: PdfExam,
    onEditClick: () => void,
}

const HealthPlanBox: React.FC<HealthPlanProps> = ({
    healthPlan,
    onEditClick,
}) => {
    console.log("ffwr")
    return (
        <div className="space-y-[25px]">
            <div className="flex items-center space-x-[20px]">
                <div className="font-bold text-[16px] leading-[33px]">
                    Health Plan
                </div>
                <div
                    className="cursor-pointer font-bold text-[12px] leading-[33px] text-holifya-blue"
                    onClick={onEditClick}
                >
                    Modifica
                </div>
            </div>
            <div className="w-full p-[20px] rounded-[15px] shadow-platform">
                <div className="w-full h-full flex items-center justify-between">
                    <div>
                        <div className="font-bold text-[14px] leading-[33px]">
                            Health plan file
                        </div>
                        <div className="font-medium text-[20px] leading-[24px]">
                            {healthPlan.filename}
                        </div>
                    </div>
                    <div
                        className="font-bold text-[12px] leading-[17px] p-[10px] cursor-pointer"
                        onClick={() => window.open(healthPlan.url)}
                    >
                        Scarica
                    </div>
                </div>
            </div>
        </div>
    )
}

interface MealPlanProps {
    dietId: string,
    dietPdfAvailable: boolean,
    shoppingListId: string,
    shoppingListPdfAvailable: boolean,
    eatingHabitsId: string,
    eatingHabitsPdfAvailable: boolean,
    tipsAndRecepesId: string,
    tipsAndRecepesPdfAvailable: boolean,
    fruitsAndVegetablesId: string,
    fruitsAndVegetablesPdfAvailable: boolean,
    topNutritionRecommendations: string[],
    topIntegrators: string[],
    topHeroFood: string[],
    keyParameters: string[],
    obiettivo: string,
    acqua: string,
    peso: string
    sonno: string,
    energia: string,
    digestione: string,
    esercizio: string,
    mealPlan: MealPlan,
    patient: Patient,
}

const MealPlanBox: React.FC<MealPlanProps> = ({
    dietId,
    dietPdfAvailable,
    shoppingListId,
    shoppingListPdfAvailable,
    eatingHabitsId,
    eatingHabitsPdfAvailable,
    tipsAndRecepesId,
    tipsAndRecepesPdfAvailable,
    fruitsAndVegetablesId,
    fruitsAndVegetablesPdfAvailable,
    topNutritionRecommendations,
    topIntegrators,
    topHeroFood,
    keyParameters,
    obiettivo,
    acqua,
    peso,
    sonno,
    energia,
    digestione,
    esercizio,
    mealPlan,
    patient,
}) => {
    const handleParamTitle = (param: string) => {
        switch (param) {
            case "obiettivo":
                return "🎯 Obiettivo"
            case "acqua":
                return "💧 Acqua"
            case "peso":
                return "⚖️ Peso"
            case "sonno":
                return "🛌 Sonno"
            case "energia":
                return "⚡ Energia"
            case "digestione":
                return "🫃 Digestione"
            case "esercizio":
                return "💪 Esercizio"
            default:
                return ""
        }
    }

    const handleParamValue = (param: string) => {
        switch (param) {
            case "obiettivo":
                return obiettivo
            case "acqua":
                return acqua
            case "peso":
                return `${peso} kg`
            case "sonno":
                return sonno
            case "energia":
                return energia
            case "digestione":
                return digestione
            case "esercizio":
                return esercizio
            default:
                return ""
        }
    }

    const navigate = useNavigate()

    const id = mealPlan.id

    return (
        <div className="space-y-[25px]">
            <div className="flex items-center space-x-[20px]">
                <div className="font-bold text-[16px] leading-[33px]">
                    Meal Plan
                </div>
                <div
                    className="cursor-pointer font-bold text-[12px] leading-[33px] text-holifya-blue"
                    onClick={() => navigate(`edit-meal-plan-page?meal_plan=${mealPlan.id}&patient_id=${patient.user_id}`)}
                >
                    Modifica
                </div>
            </div>
            <div className="w-full flex gap-2 flex-wrap">
                {
                    keyParameters.map((param, index) => (
                        <div
                            key={index}
                            className="w-[136px] h-[136px] border border-solid border-black bg-[#DAE8FD] rounded-[15px] p-[10px] flex flex-col space-y-[10px]"
                        >
                            <div className="w-full flex items-center justify-between">
                                <div className="font-bold text-[14px] leading-[33px]">{handleParamTitle(param)}</div>
                            </div>
                            <div className="font-bold text-[22px] leading-[28px] break-words">{handleParamValue(param)}</div>
                        </div>
                    ))
                }
            </div>
            <div className="grid grid-cols-2 gap-4">
                <div className="w-full p-[20px] rounded-[15px] flex flex-col space-y-[15px] border border-black border-solid">
                    <div className="font-bold text-[14px] leading-[33px]">
                        👩‍ Top nutrition recommendation per te
                    </div>
                    <div>
                        {
                            topNutritionRecommendations.map((nutri, index) => (
                                <div key={index}>
                                    {nutri}
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="w-full p-[20px] rounded-[15px] flex flex-col space-y-[15px] border border-black border-solid">
                    <div className="font-bold text-[14px] leading-[33px]">
                        🥑 Top 3 hero foods per te
                    </div>
                    <div>
                        {
                            topHeroFood.map((nutri, index) => (
                                <div key={index}>
                                    {nutri}
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="w-full p-[20px] rounded-[15px] flex flex-col space-y-[15px] border border-black border-solid">
                    <div className="font-bold text-[14px] leading-[33px]">
                        💊 Top integratori per te
                    </div>
                    <div>
                        {
                            topIntegrators.map((nutri, index) => (
                                <div key={index}>
                                    {nutri}
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="w-full p-[20px] rounded-[15px] bg-[#F7F7F7] flex flex-col space-y-[15px]">
                    <div className="font-bold text-[14px] leading-[33px]">
                        File condivisi:
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <PdfRow type={PdfType.Diet} pdfAvailable={dietPdfAvailable && dietId === id} />
                        <PdfRow type={PdfType.EatingHabits} pdfAvailable={eatingHabitsPdfAvailable && eatingHabitsId === id} />
                        <PdfRow type={PdfType.ShoppingList} pdfAvailable={shoppingListPdfAvailable && shoppingListId === id} />
                        <PdfRow type={PdfType.SeasonalFruitsAndVegetables} pdfAvailable={fruitsAndVegetablesPdfAvailable && fruitsAndVegetablesId === id} />
                        <PdfRow type={PdfType.TipsAndRecepes} pdfAvailable={tipsAndRecepesPdfAvailable && tipsAndRecepesId === id} />
                    </div>
                </div>
            </div>
        </div>
    )
}

interface PdfRowProps {
    type: PdfType,
    pdfAvailable: boolean,
}

const PdfRow: React.FC<PdfRowProps> = ({
    type,
    pdfAvailable,
}) => {
    const handleName = () => {
        switch (type) {
            case PdfType.Diet:
                return "Dieta"
            case PdfType.EatingHabits:
                return "Eating habits"
            case PdfType.ShoppingList:
                return "Lista della spesa"
            case PdfType.SeasonalFruitsAndVegetables:
                return "Frutta e verdura di stagione"
            case PdfType.TipsAndRecepes:
                return "Tips e ricette"
        }
    }
    return (
        <div className="font-normal text-[14px] leading-[16px] flex">
            <div><img src={pdfAvailable ? "/images/symbols/pdf-available.svg" : "/images/symbols/pdf-not-available.svg"} className="mr-[10px]" /></div><div>{handleName()}</div>
        </div>
    )
}

interface SharedFileProps {
    patient: Patient,
}

const SharedFilesBox: React.FC<SharedFileProps> = ({
    patient,
}) => {
    const navigate = useNavigate()
    return (
        <div className="space-y-[25px]">
            <div className="font-bold text-[16px] leading-[33px]">File condivisi</div>
            <div className="space-y-[20px]">
                <FolderBox
                    folder={Folder.SharedFromEquipe}
                    pointer={true}
                    onClick={() => { navigate(`shared-from-equipe-page?patient_id=${patient.user_id}`) }}
                />
                <FolderBox
                    folder={Folder.SharedFromPatient}
                    pointer={true}
                    onClick={() => { navigate(`shared-from-customer-page?patient_id=${patient.user_id}`) }}
                    patient={patient}
                />
            </div>
        </div>
    )
}

interface FolderProps {
    folder: Folder,
    pointer: boolean,
    textTwo?: string,
    textThree?: string,
    onClick?: () => void,
    isLoading?: boolean,
    patient?: Patient,
}

const FolderBox: React.FC<FolderProps> = ({
    folder,
    pointer,
    textTwo,
    textThree,
    onClick,
    isLoading = false,
    patient,
}) => {
    const handleFolderName = () => {
        switch (folder) {
            case Folder.HealthAssessment:
                return "📝  Health Assessment"
            case Folder.DnaTest:
                return "🧬  DNA Test Complete"
            case Folder.BloodTest:
                return "🩸  Analisi degli Aminoacidi"
            case Folder.ResultsAndProgresses:
                return "📊  Risultati e progressi"
            case Folder.SharedFromEquipe:
                return "🗂️  Caricati dall'Equipe Medica"
            case Folder.SharedFromPatient:
                return `🗂️  Caricati da ${patient?.user_name}`
            case Folder.Nutritionist:
                return `👨‍⚕️  ${textTwo === "null null" ? "Nessun nutrizionista associato" : textTwo}`
            case Folder.HealthPlan:
                return "Health Plan"
        }
    }
    let textTwoReal = textTwo
    if (textTwo === "null null") textTwoReal = undefined
    return (
        <div
            className={`w-full h-[90px] flex items-center rounded-[5px] shadow-platform ${(pointer && !isLoading) ? "cursor-pointer" : "cursor-not-allowed"} px-[20px] py-[10px]`}
            onClick={() => { if (pointer && !isLoading && onClick) onClick() }}
        >
            <div className="w-full h-full flex items-center justify-between space-x-[10px] overflow-hidden">
                <div
                    className={`font-normal text-[20px] leading-[33px] ${textTwoReal ? "flex-[0.5] truncate" : "flex-1 whitespace-nowrap"
                        }`}
                >
                    {handleFolderName()}
                </div>
                {/* TextTwo */}
                {folder !== Folder.Nutritionist && (
                    <div
                        className={`w-[20%] min-w-[0] truncate text-ellipsis overflow-hidden 
                            ${textTwo && textThree ? "hidden sm:block" : ""}`}
                    >
                        {textTwo}
                    </div>
                )}

                {/* TextThree and Loading */}
                <div className="w-[30%] flex justify-end whitespace-nowrap">
                    {isLoading ? <LoadingSpinner /> : textThree}
                </div>
            </div>
        </div>
    )
}

interface AppointementProps {
    futureAppointments: Appointment[],
    oldAppointments: Appointment[],
    nutritionist: string,
}

const AppointmentsBox: React.FC<AppointementProps> = ({
    futureAppointments,
    oldAppointments,
    nutritionist,
}) => {
    return (
        <div className="space-y-[25px]">
            <div className="font-bold text-[16px] leading-[33px]">
                Appuntamenti
            </div>
            {
                futureAppointments.map((app, index) =>
                    <SmallAppointmentWidget
                        key={index}
                        appointment={app}
                        nutritionist={nutritionist}
                    />
                )
            }
            {
                oldAppointments.map((app, index) =>
                    <OldAppointmentWidget
                        key={index}
                        appointment={app}
                        nutritionist={nutritionist}
                    />
                )
            }
        </div>
    )
}

interface NutritionistBoxProps {
    nutritionist: Nutritionist,
}

const NutritionistBox: React.FC<NutritionistBoxProps> = ({
    nutritionist,
}) => {
    return (
        <div className="space-y-[25px]">
            <div className="font-bold text-[16px] leading-[33px]">
                Nutrizionista associato
            </div>
            <div className="space-y-[20px]">
                <FolderBox folder={Folder.Nutritionist} pointer={false} textTwo={`${nutritionist.name} ${nutritionist.family_name}`} textThree={nutritionist.email} />
            </div>
        </div>
    )
}
