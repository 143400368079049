import { HealthPlan, MealPlan } from "../api-objects/meal-plan"
import { Patient } from "../api-objects/Patient"
import { BASE_URL, requestOptionsGET } from "../Constants"
import { ErrorWithMessageCallback, GenericSuccessCallback, GetMealPlanSuccessCallback } from "./callback-helpers"

export async function callCreateMealPlanApi(
    patient: Patient,
    idToken: string,
    nutritionRecommendations: string[],
    heroFoods: string[],
    integratori: string[],
    importantParameters: string[],
    onSuccess: GenericSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    const response = await fetch(`${BASE_URL}/api/meal-plans`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': idToken,
        },
        body: JSON.stringify(
            {
                user_id: patient.user_id,
                data: {
                    nutrition_recommendations: nutritionRecommendations,
                    top_hero_foods: heroFoods,
                    top_integratori: integratori,
                    key_parameters: importantParameters,
                },
                user_email: patient.email,
                user_name: patient.user_name,
            }
        )
    })
    if (response.ok) {
        const responseJson = await response.json()
        onSuccess(responseJson)
    } else {
        onError("Creazione Meal Plan errata")
    }
}

export async function getMealPlanApi(
    idToken: string,
    user_id: string,
    onSuccess: GetMealPlanSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    const response = await fetch(`${BASE_URL}/api/meal-plans/user/${user_id}`, requestOptionsGET(idToken))
    if (response.ok) {
        const mealPlan: MealPlan = await response.json()
        onSuccess(mealPlan)
    } else if (response.status === 404) {
        onError("Meal plan non trovato")
    } else {
        onError("Errore nel recupero del meal plan")
    }
}

export async function getMealPlanByIdApi(
    idToken: string,
    id: string,
    onSuccess: GetMealPlanSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    const response = await fetch(`${BASE_URL}/api/meal-plans/id/${id}`, requestOptionsGET(idToken))
    if (response.ok) {
        const mealPlan: MealPlan = await response.json()
        onSuccess(mealPlan)
    } else if (response.status === 404) {
        onError("Meal plan non trovato")
    } else {
        onError("Errore nel recupero del meal plan")
    }
}

export async function callCreateHealthPlanApi(
    idToken: string,
    formData: FormData
): Promise<any> {
    try {
        const response = await fetch(`${BASE_URL}/api/health-plans`, {
            method: 'POST',
            headers: {
                'Authorization': idToken,
            },
            body: formData
        })
        if (!response.ok) {
            throw new Error("Creazione Health Plan errata");
        }
        return await response.json();
    } catch (error: unknown) {
        if (error instanceof Error) {
            throw new Error(error.message);
        } else {
            throw new Error("Errore durante la creazione dell'Health Plan");
        }
    }
}

export async function getHealthPlansByUserId(
    userId: string,
    idToken: string,
): Promise<HealthPlan[]> {
    try {
        const response = await fetch(`${BASE_URL}/api/health-plans/user/${userId}`, requestOptionsGET(idToken));
        if (response.ok) {
            const healthPlans: HealthPlan[] = await response.json();
            return healthPlans;
        } else if (response.status === 401) {
            throw new Error("Session expired");
        } else if (response.status === 404) {
            return []
        } else {
            throw new Error("Errore nel recuperare l'health plan");
        }
    } catch (error) {
        if (error instanceof Error)
            if (error.message.includes("Session expired")) {
                throw new Error("Session expired");
            } else if (error.message.includes("Nessun health plan trovato")) {
                throw new Error(error.message);
            }
        throw new Error("An unexpected error occurred");
    }
}

export async function getHealthPlansById(
    id: string,
    idToken: string,
): Promise<HealthPlan | null> {
    try {
        const response = await fetch(`${BASE_URL}/api/health-plans/id/${id}`, requestOptionsGET(idToken));
        if (response.ok) {
            const healthPlan: HealthPlan = await response.json();
            return healthPlan;
        } else if (response.status === 401) {
            throw new Error("Session expired");
        } else if (response.status === 404) {
            return null
        } else {
            throw new Error("Errore nel recuperare l'health plan");
        }
    } catch (error) {
        if (error instanceof Error)
            if (error.message.includes("Session expired")) {
                throw new Error("Session expired");
            } else if (error.message.includes("Nessun health plan trovato")) {
                throw new Error(error.message);
            }
        throw new Error("An unexpected error occurred");
    }
}

export async function callUpdateHealthPlanDataApi(
    idToken: string,
    health_plan_id: string,
    user_id: string,
    data: any,
): Promise<any> {
    try {
        console.log(
            data,
        )
        const response = await fetch(`${BASE_URL}/api/health-plans/${health_plan_id}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify({
                user_id,
                data,
            })
        })
        if (!response.ok) {
            throw new Error("Aggiornamento Health Plan errato");
        }
        return await response.json();
    } catch (error: unknown) {
        if (error instanceof Error) {
            throw new Error(error.message);
        } else {
            throw new Error("Errore durante l'aggiornamento dell'Health Plan");
        }
    }
}

export async function getHealthPlanPdf(
    userId: string,
    idToken: string,
    folder: string,
): Promise<any> {
    try {
        const response = await fetch(`${BASE_URL}/api/health-plans/user/${userId}/pdfs?folder=${folder}`, requestOptionsGET(idToken));
        if (response.ok) {
            const uploadUrlResponse = await response.json();
            return uploadUrlResponse
        } else if (response.status === 401) {
            throw new Error("Session expired");
        } else if (response.status === 404) {
            return []
        } else {
            throw new Error("Errore nel recuperare l'health plan");
        }
    } catch (error) {
        if (error instanceof Error)
            if (error.message.includes("Session expired")) {
                throw new Error("Session expired");
            } else if (error.message.includes("Nessun health plan trovato")) {
                throw new Error(error.message);
            }
        throw new Error("An unexpected error occurred");
    }
}
